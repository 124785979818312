@import url(https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@300;400;500;700&display=swap);
@charset "utf-8";
/* CSS Document */

	/* http://meyerweb.com/eric/tools/css/reset/
02
v2.0 | 20110126
03
License: none (public domain)
04
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
display: block;
}
body {
line-height: 1;
}
ol, ul {
list-style: none;
}
blockquote, q {
quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
content: '';
content: none;
}
table {
border-collapse: collapse;
border-spacing: 0;
}
*,*::before,*::after {box-sizing: border-box}
html{font-size:62.5%;}
body{font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;}
a:link,a:visited,a:hover,a:active{text-decoration: none;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
.App{
  height: 100%;
  min-width: 320px;
}
input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type='checkbox'],
input[type='radio'] {
  display: none;
}

input[type='submit'],
input[type='button'],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.ttlMis{
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 5px;
}
.ttlMis::before{content: "・";}

.codeBox{
  background-color: #2B536A;
  color: #ffffff;
  border-radius: 3px;
  padding: 15px;
  overflow: overlay;
}
.tag{
  color:#F970A1;
}
.cla{
  color:#A6E22E;
}
.cna{
  color:#E6DB74;
}
.com{
  color:#79a0b7;
}
.table,
.table tr,
.table td{
  border:1px solid #CCCCCC;
  padding: 5px 10px;
}
.table th{
  background-color: #2B536A;
  color:#ffffff;
  border-right: 1px solid #cccccc;
}


.table{
  width: 80%;
  margin: 10px 0px 20px 0px;
}
@media screen and (max-width:480px) {
  .table{
    width: 100%;
  }
  .table th{
    padding: 2px 0px;
  }
}
.wid-20{width: 20%;}
.wid-30{width: 30%;}
.wid-40{width: 40%;}
.wid-50{width: 50%;}
